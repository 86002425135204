<template>
    <div class="c-app flex-row align-items-center" :class="{ 'c-dark-theme': $store.state.darkMode }">
        <CContainer>
            <CRow class="justify-content-center">
                <CCol md="6">
                    <CCardGroup>
                        <CCard class="p-4">
                            <CCardBody>
                                <CForm @submit="submit" action="">
                                    <h1>Login</h1>
                                    <p class="text-muted">Sign In to your account</p>
                                    <CInput
                                            placeholder="Username"
                                            autocomplete="username email"
                                            v-model="username" name="username"
                                    >
                                        <template #prepend-content>
                                            <CIcon name="cil-user"/>
                                        </template>
                                    </CInput>
                                    <CInput
                                            placeholder="Password"
                                            type="password"
                                            autocomplete="curent-password"
                                            v-model="password" name="password"
                                    >
                                        <template #prepend-content>
                                            <CIcon name="cil-lock-locked"/>
                                        </template>
                                    </CInput>
                                    <CRow>
                                        <CCol col="6" class="text-left">
                                            <CButton color="primary" class="px-4" @click="submit">Login</CButton>
<!--                                            <CLoadingButton :loading="loading" spinnerType="grow" class="px-4" color="primary" @click="submit">Login</CLoadingButton>-->
                                        </CCol>
                                        <CCol col="6" class="text-right">
                                            <CButton color="link" class="px-0">Forgot password?</CButton>
                                            <CButton color="link" class="d-lg-none">Register now!</CButton>
                                        </CCol>
                                    </CRow>
                                </CForm>
                            </CCardBody>
                        </CCard>
                        <!--            <CCard
                                      color="primary"
                                      text-color="white"
                                      class="text-center py-5 d-md-down-none"
                                      body-wrapper
                                    >
                                      <CCardBody>
                                        <h2>Sign up</h2>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                        <CButton
                                          color="light"
                                          variant="outline"
                                          size="lg"
                                        >
                                          Register Now!
                                        </CButton>
                                      </CCardBody>
                                    </CCard>-->
                    </CCardGroup>
                </CCol>
            </CRow>
        </CContainer>
    </div>
</template>

<script>
    import {mapState, mapActions} from 'vuex'
    export default {
      name: 'Login',
      data () {
        return {
          username: '',
          password: '',
          submitted: false,
          loading:false
        }
      },
      computed: {
        ...mapState('account', ['status'])
      },
      created () {
        // reset login status
        this.logout();
      },
      methods: {
        ...mapActions('account', ['login', 'logout']),
        submit() {
          this.submitted = true;
          const { username, password } = this;
          if (username && password) {
            this.loading = true;
            this.login({ username, password })
          }
        }
      }
    };

</script>
